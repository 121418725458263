import { Avatar, Box, Skeleton } from '@mui/material';
import type { FC } from 'react';
import usePromise from 'react-use-promise';
import { useCache } from '../../providers/cache.js';
import { formatName } from '../../utils/format.js';

export const PersonEntity: FC<{ avatar?: boolean; personId: string }> = ({
  avatar = true,
  personId,
}) => {
  const { person } = useCache();
  const [data, error, state] = usePromise(
    () => person.get(personId),
    [personId],
  );

  if (state === 'pending') return <Skeleton variant="rounded" />;
  if (state === 'rejected') return <>Error: {error.message}</>;
  if (!data) return null;

  const name = formatName(data);

  return (
    <>
      {avatar ? (
        <Avatar
          alt={name}
          src={data?.picture ?? undefined}
          sx={{
            width: '1em',
            height: '1em',
            display: 'inline-flex',
            verticalAlign: 'text-bottom',
            mr: '0.5em',
            '& .MuiAvatar-img': { verticalAlign: 'text-top' },
          }}
        />
      ) : null}
      <Box sx={{ display: 'inline' }}>{name}</Box>
    </>
  );
};

export const PersonAvatar: FC<{ personId: string; size?: number | string }> = ({
  personId,
  size = '1em',
}) => {
  const { person } = useCache();
  const [data, error, state] = usePromise(
    () => person.get(personId),
    [personId],
  );

  if (state === 'pending') return <Skeleton variant="rounded" />;
  if (state === 'rejected') return <>Error: {error.message}</>;
  if (!data) return null;

  const name = formatName(data);

  return (
    <Avatar
      alt={name}
      src={data?.picture ?? undefined}
      imgProps={{ title: name }}
      sx={{
        fontSize: size,
        width: '1em',
        height: '1em',
      }}
    />
  );
};
